import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { HalfScreen, Loader, ErrorToast } from 'components';
import { PageProps } from 'components/Auth';
import HalfScreenResponsive from 'components/HalfScreen/HalfScreenResponsive';
import { Role } from 'components/UsersList/UsersList';
import { GET_ROLES } from 'data/roles';
import { GET_USER_COMPANIES } from 'data/user';
import USER_COMPANIES_QUERY from 'data/user/get-user-companies';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { getSession, signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { serverClient } from 'src/urqlClient';
import { useQuery } from 'urql';
import { getStripe, isClient } from 'utils';

export const USER_PRICE = 9;

// In-memory cache using Map
const userCompaniesCache = new Map();

export default function CreateCompany({ userHasCompanies }: { userHasCompanies: boolean }): ReactElement {
  const router = useRouter();
  const [companyName, setCompanyName] = useState<string>();
  const [coachEmail, setCoachEmail] = useState<string>();
  const [haveACoach, setHaveACoach] = useState<boolean>(true);
  const [numUsers] = useState<number>(1);
  const [creating, setCreating] = useState<boolean>(false);
  const [rolesRes] = useQuery({ query: GET_ROLES });
  const roles = useMemo(() => rolesRes.data?.roles || [], [rolesRes]);
  const coachRole = useMemo(() => roles.find((role) => role.name === 'Coach'), [roles]);
  const [error, setError] = useState(null);
  const { data: session } = useSession();
  // const [companiesResult] = useQuery({
  //   query: USER_COMPANIES_QUERY,
  //   variables: { userId: session?.id },
  // });

  // const companyUsers = companiesResult?.data?.company_users || [];
  // if (companyUsers.length && isClient()) {
  //   router.push('/');
  // }

  // Fetch companies from the cache or the API
  const userId = session?.id;

  const handleCheckout = useCallback(async () => {
    setCreating(true);
    if (haveACoach && !coachEmail) {
      setError({
        name: 'Company Creation',
        message:
          'Please enter your coach`s email. If you dont have a coach please check the dont have a coach checkbox.',
      });
      setCreating(false);
      return;
    }

    const companyResponse = await fetch(`/api/company/company-exist?company-name=${companyName}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });

    if (companyResponse?.status !== 200) {
      setError({
        name: 'Company Creation',
        message: 'There was an issue in company creation. Please try again.',
      });
      setCreating(false);
      return;
    }

    const companies = await companyResponse.json();
    if (companies?.length > 0) {
      signOut({ callbackUrl: '/auth/signup?company-exist=true' });
      return;
    }

    const role = roles.find((r: Role) => r.name === 'Admin');
    const customerResponse = await fetch('/api/billing/create-customer', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        companyName,
        roleId: role.id,
      }),
    });

    const { customer, leadership_team_id } = await customerResponse.json();
    const response = await fetch('/api/billing/subscription-session', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        quantity: numUsers,
        companyId: customer.metadata?.companyId,
        customerId: customer.id,
      }),
    });

    if (haveACoach) {
      const newAppUsersInsertData = [
        {
          company_id: customer.metadata?.companyId,
          role_id: coachRole?.id,
          email: coachEmail.toLowerCase(),
          team_id: leadership_team_id,
        },
      ];
      await fetch('/api/email/invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ objects: newAppUsersInsertData }),
      });
    }

    const { sessionId } = await response.json();
    const stripe = await getStripe();
    await stripe.redirectToCheckout({ sessionId });
    setCreating(false);
  }, [numUsers, companyName, roles, coachEmail, haveACoach, coachRole?.id]);

  const steps = [
    { name: 'signup', status: 'complete' },
    { name: 'company', status: 'current' },
    { name: 'teams', status: '' },
    { name: 'users', status: '' },
  ];

  return (
    <>
      <HalfScreenResponsive
        image={{
          src: '/company-create.png',
        }}
      >
        <div>
          <div className="w-16 h-16">
            <Image
              src="/system-and-soul-logo.svg"
              height={50}
              width={100}
              alt="Logo"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
          <div className="relative mt-4">
            <div className="w-auto mx-auto">
              <div className="mx-auto">
                <div className="text-center mx-auto">
                  <div className="flex justify-between items-center">
                    <button
                      className="flex justify-left items-center text-gray-500"
                      onClick={() => {
                        if (!userHasCompanies) {
                          signOut({ callbackUrl: '/' });
                          return;
                        }
                        router.beforePopState(({ url }) => {
                          if (url.includes('/company/[company]')) {
                            return true;
                          } else {
                            router.push('/');
                          }
                        });
                        router.back();
                      }}
                    >
                      <ArrowSmLeftIcon className="h-5 w-5" />
                      <span className="ml-2 text-sm">Back</span>
                    </button>
                    {userHasCompanies ? null : (
                      <nav className="flex items-center" aria-label="Progress">
                        <p className="text-sm font-medium text-gray-500">
                          Step {steps.findIndex((step) => step.status === 'current') + 1} of {steps.length}
                        </p>
                        <ol role="list" className="ml-8 flex items-center space-x-5">
                          {steps.map((step) => (
                            <li key={step.name}>
                              {step.status === 'complete' ? (
                                <div className="block w-2.5 h-2.5 bg-primary-600 rounded-full">
                                  <span className="sr-only">{step.name}</span>
                                </div>
                              ) : step.status === 'current' ? (
                                <div className="relative flex items-center justify-center" aria-current="step">
                                  <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                                    <span className="w-full h-full rounded-full bg-primary-200" />
                                  </span>
                                  <span
                                    className="relative block w-2.5 h-2.5 bg-primary-600 rounded-full"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">{step.name}</span>
                                </div>
                              ) : (
                                <div className="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                                  <span className="sr-only">{step.name}</span>
                                </div>
                              )}
                            </li>
                          ))}
                        </ol>
                      </nav>
                    )}
                  </div>
                  <h2 className="mt-6 text-2xl font-bold text-gray-900 tracking-tighter">
                    Tell us about your company.
                  </h2>
                  {userHasCompanies ? null : (
                    <div className="tracking-tight">
                      <em>You have signed up using {session.user?.email}.</em>
                      <em>
                        You are being asked to create a company because there are no existing companies for the above
                        user.
                      </em>
                    </div>
                  )}
                  <div className="mt-4">
                    <div className="mr-3">
                      <div className="mb-4">
                        <label htmlFor="company_name" className="block text-xs text-left text-gray-700">
                          Company Name
                          <div className="mt-1">
                            <input
                              id="company_name"
                              name="company_name"
                              type="text"
                              required
                              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border py-2 px-3 border-gray-300 rounded-md"
                              onChange={(event) => setCompanyName(event.target.value)}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="mb-4 text-gray-700 border rounded flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center justify-center p-4">
                          <span className="text-sm">You can invite more users after logging in.</span>
                        </div>
                        <div className="w-full p-6 flex flex-col items-center justify-center bg-gray-50">
                          <ul className="text-sm">
                            <li>$90 base price for 1-6 users/month</li>
                            <li className="mt-2">$9 per additional user/per month 7-25</li>
                            <li className="mt-2">$6 per additional user/per month 26+</li>
                            <li className="mt-2">
                              <Link href="mailto:help@systemandsoul.com" className="block underline text-primary">
                                50+ users reach out for pricing
                              </Link>
                            </li>
                          </ul>
                          <div className="mt-2 text-xs text-gray-500">
                            You will not be charged until your 30 day trial ends. You can cancel your free-trial at any
                            time.
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        {haveACoach ? (
                          <label htmlFor="coach_email" className="block text-xs text-left text-gray-700">
                            Your Coach&apos;s Email*
                            <div className="mt-1">
                              <input
                                id="coach_email"
                                name="coach_email"
                                type="email"
                                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border py-2 px-3 border-gray-300 rounded-md"
                                onChange={(event) => setCoachEmail(event.target.value)}
                              />
                            </div>
                          </label>
                        ) : null}
                        <div>
                          <label htmlFor="" className="block text-xs text-left text-gray-700">
                            <input
                              type="checkbox"
                              name="dont_have_a_coach"
                              id="dont_have_a_coach"
                              onChange={(event) => {
                                const checkedStatus = event.target.checked;
                                checkedStatus ? setHaveACoach(false) : setHaveACoach(true);
                              }}
                            />{' '}
                            I don&apos;t have a coach{' '}
                          </label>
                        </div>
                      </div>
                      <button
                        className="w-full px-8 py-3 mb-6 bg-primary rounded-md text-white text-sm flex items-center justify-center"
                        onClick={handleCheckout}
                        disabled={creating}
                      >
                        {creating ? <Loader color="text-white" /> : 'Continue'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HalfScreenResponsive>
      <ErrorToast error={error} setError={setError} />
    </>
  );
}

CreateCompany.auth = true;

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<PageProps>> {
  const session = await getSession(context);
  const sClient = serverClient(session?.token as unknown as string);

  // Check if data is already cached
  const userId = session?.id;

  // Fetch data from the API if not cached
  const userCompaniesRes = await sClient.query(GET_USER_COMPANIES, { userId }).toPromise();
  // Cache the result
  const userHasCompanies = Boolean(userCompaniesRes?.data?.company_users?.length);

  return userHasCompanies && context.query.newUser
    ? {
        redirect: {
          destination: '/',
          permanent: false,
        },
      }
    : {
        props: {
          pageTitle: 'Create company',
          session,
          userHasCompanies,
          hideSidebar: true,
        },
      };
}
