import S2HalfScreenImage from 'components/S2HalfScreenImage';
import { ReactElement, ReactNode } from 'react';
import Image from 'next/image';
import HalfScreenResponsiveImage from 'components/S2HalfScreenImage/S2HalfScreenResponsiveImage';

type ImageType = {
  preText: string;
  postText: string;
  background?;
};

type HalfScreenProps = {
  children: ReactNode;
  image?: {
    preText?: string | ReactElement;
    postText?: string | ReactElement;
    background?: string;
    src?: string;
  };
};

export default function HalfScreenResponsive({ children, image }: HalfScreenProps): ReactElement {
  return (
    <div className="flex flex-col md:flex-row min-h-screen w-screen bg-white">
      <div className="flex-1 flex flex-col justify-center p-12">
        <div className="mx-auto w-full">{children}</div>
      </div>
      {image && image.src && <HalfScreenResponsiveImage src={image.src} alt="" />}
    </div>
  );
}
