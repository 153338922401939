import React from 'react';

type HalfScreenResponsiveImageProps = {
  src: string;
  alt: string;
};

export default function HalfScreenResponsiveImage({ src, alt }: HalfScreenResponsiveImageProps): React.ReactElement {
  return (
    <div className="hidden lg:block lg:w-1/2 h-full">
      <img src={src} alt={alt} className="w-full h-full object-cover" />
    </div>
  );
}
